import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import trainingsApi from 'services/trainingsApi'

import actions from './actions'
import patientsApi from "../../services/patientsApi";

export function* GET_TRAINING({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.getTraining, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        training: response.data,
      },
    })
  } else {
    yield history.push('/manager/trainings')
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_TRAININGS({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.getTrainings, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TRAINING({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.createTraining, payload)
  if (response && response.status === 200) {
    yield history.push(`/manager/trainings/update/${response.data.id}`)
    notification.success({
      message: 'Success!',
      description: 'Training created successfully!',
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_TRAINING({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.updateTraining, payload)
  if (response && response.status === 200) {
    yield history.push('/manager/trainings')
    notification.success({
      message: 'Success!',
      description: 'Training updated successfully!',
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_TRAINING({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.deleteTraining, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Training removed successfully!',
    })

    yield put({
      type: 'trainings/GET_TRAININGS',
      payload,
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD_TRAINING_ASSET({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.downloadAsset, payload.assetId, payload.trainingId)
  if (response && response.status === 200) {
    if(response.data.url) {
      window.open(response.data.url)
    }
  } else {
    notification.error({
      message: 'Error!',
      description: 'Unfortunately we could not retrieve the document for this patient. Please try again later'
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_CATEGORIES() {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.getCategories)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        categories: response.data,
      },
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* PUBLIC_GET_TRAINING_CATEGORIES() {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicCategories: true,
    },
  })
  const response = yield call(trainingsApi.getPublicCategories)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        publicCategories: response.data,
      },
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicCategories: false,
    },
  })
}

export function* PUBLIC_GET_TRAININGS_LIST({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(trainingsApi.getPublicTrainings, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        publicList: response.data,
      },
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* PUBLIC_GET_TRAINING({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublic: true,
    },
  })
  const response = yield call(trainingsApi.getPublicTraining, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        publicTraining: response.data,
      },
    })
  } else {
    yield history.push('/training')
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublic: false,
    },
  })
}
export function* PUBLIC_GET_TRAINING_QUESTIONS({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicQuiz: true,
    },
  })
  const response = yield call(trainingsApi.getPublicTrainingQuestion, payload.id, payload.index)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        publicQuestion: response.data,
      },
    })
  } else {
    yield history.push(`/training/${payload.id}`)
    notification.error({
      message: 'Error!',
      description: 'Unfortunately the quiz for this training is not available at this time.'
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicQuiz: false,
    },
  })
}
export function* PUBLIC_SUBMIT_TRAINING_QUESTIONS({ payload }) {
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicQuiz: true,
    },
  })
  const response = yield call(trainingsApi.submitPublicTrainingQuestion, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        quizResults: response.data,
      },
    })
  } else {
    yield put({
      type: 'trainings/SET_STATE',
      payload: {
        quizResults: { status: "failed" },
      },
    })
  }
  yield put({
    type: 'trainings/SET_STATE',
    payload: {
      loadingPublicQuiz: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRAININGS, GET_TRAININGS),
    takeEvery(actions.CREATE_TRAINING, CREATE_TRAINING),
    takeEvery(actions.GET_TRAINING, GET_TRAINING),
    takeEvery(actions.UPDATE_TRAINING, UPDATE_TRAINING),
    takeEvery(actions.DELETE_TRAINING, DELETE_TRAINING),
    takeEvery(actions.DOWNLOAD_TRAINING_ASSET, DOWNLOAD_TRAINING_ASSET),
    takeEvery(actions.GET_CATEGORIES, GET_CATEGORIES),

    // public sagas
    takeEvery(actions.PUBLIC_GET_TRAINING_CATEGORIES, PUBLIC_GET_TRAINING_CATEGORIES),
    takeEvery(actions.PUBLIC_GET_TRAININGS_LIST, PUBLIC_GET_TRAININGS_LIST),
    takeEvery(actions.PUBLIC_GET_TRAINING, PUBLIC_GET_TRAINING),
    takeEvery(actions.PUBLIC_GET_TRAINING_QUESTIONS, PUBLIC_GET_TRAINING_QUESTIONS),
    takeEvery(actions.PUBLIC_SUBMIT_TRAINING_QUESTIONS, PUBLIC_SUBMIT_TRAINING_QUESTIONS),
  ])
}
