import actions from './actions'

const initialState = {
  list: { data: [], meta: {} },
  categories: [],
  training: {},
  publicQuestion: {},

  loading: false,

  // public reducers
  loadingPublic: false,
  loadingPublicQuiz: false,
  loadingPublicCategories: false,

  publicCategories: [],
  publicList: { data: [], meta: {} },
  publicTraining: {}

}

export default function trainingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
