import { all, put, call } from 'redux-saga/effects'
import { getMenuData, getMenuTopData } from 'services/menu'

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* GET_TOP_DATA() {
  const menuTopData = yield call(getMenuTopData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuTopData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
    GET_TOP_DATA(), // run once on app load to fetch menu data
  ])
}
