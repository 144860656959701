import NProgress from 'nprogress';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import AuthLayout from './Auth';
import MainLayout from './Main';
import PublicLayout from './Public';

// import Loader from 'components/cleanui/layout/Loader'
const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ auth }) => ({ auth })
let previousPath = ''

const Layout = ({ auth, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    // if (pathname === '/') {
    if (/^\/(manager)(?=\/|$)/i.test(pathname)) {
        return 'main'
    }
    if (/^\/(auth|refer-patient)(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'public'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = (auth && auth.authorized) || false
  const isUserLoading = (auth && auth.loading) || false
  const isAuthLayout = getLayout() === 'auth'
  const isPublicLayout = getLayout() === 'public'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isPublicLayout && !isAuthLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="ReactNR | %s" title="." />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
