import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import auth from './auth/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

import patients from './patients/reducers'
import checkups from './checkups/reducers'
import trainings from './trainings/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    menu,
    settings,
    patients,
    checkups,
    trainings,
  })
