import apiClient from 'services/axios'

const patientsApi = {
  getPatients(params) {
    return apiClient
      .get('patients', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPatient(id) {
    return apiClient
      .get(`patients/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  createPatient(data) {
    return apiClient
      .post('patients', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  updatePatient(data) {
    return apiClient
      .put('patients', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  deletePatient(id) {
    return apiClient
      .delete(`patients/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPatientCheckup(patientId, checkupId) {
    return apiClient
      .get(`patients/${patientId}/checkups/${checkupId}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPatientCheckups(params) {
    return apiClient
      .get('checkups', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPatientDocuments(patientId) {
    return apiClient
      .get(`patients/${patientId}/documents`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  downloadPatientDocuments(id, patientId) {
    return apiClient
      .get(`patients/${patientId}/documents/${id}/download`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  // uploadDocument(patientId, data) {
  //   return apiClient
  //     .post(`patients/upload`, data)
  //     .then(response => response)
  //     .catch(err => {
  //       console.log(err)
  //       return err
  //     })
  // },
  uploadDocument(patientId, data) {
    return apiClient
      .post(`patients/upload`, data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  referPatient(data) {
    return apiClient
      .post(`patients/refer`, data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  addPatientCheckup(data) {
    return apiClient
      .post(`patients/add-checkup`, data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },

}

export default patientsApi
