import actions from './actions'

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  avatar: '',
  signature: '',
  accountComplete: false,
  accountCompletePercent: 0,
  volunteer: false,
  authorized: false, // false is default value
  loading: false,
  inviteDetails: false,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return initialState
    default:
      return state
  }
}
