const actions = {
  SET_STATE: 'patients/SET_STATE',

  GET_PATIENT: 'patients/GET_PATIENT',
  GET_PATIENTS: 'patients/GET_PATIENTS',
  CREATE_PATIENT: 'patients/CREATE_PATIENT',
  UPDATE_PATIENT: 'patients/UPDATE_PATIENT',
  DELETE_PATIENT: 'patients/DELETE_PATIENT',

  GET_PATIENT_CHECKUPS: 'patients/GET_PATIENT_CHECKUPS',
  GET_PATIENT_DOCUMENTS: 'patients/GET_PATIENT_DOCUMENTS',
  DOWNLOAD_PATIENT_DOCUMENT: 'patients/DOWNLOAD_PATIENT_DOCUMENT',
  UPLOAD_PATIENT_DOCUMENT: 'patients/UPLOAD_PATIENT_DOCUMENT',

  REFER_PATIENT: 'patients/REFER_PATIENT',
}

export default actions
