const actions = {
  SET_STATE: 'trainings/SET_STATE',

  GET_TRAINING: 'trainings/GET_TRAINING',
  GET_TRAININGS: 'trainings/GET_TRAININGS',
  CREATE_TRAINING: 'trainings/CREATE_TRAINING',
  UPDATE_TRAINING: 'trainings/UPDATE_TRAINING',
  DELETE_TRAINING: 'trainings/DELETE_TRAINING',

  GET_CATEGORIES: 'trainings/GET_CATEGORIES',

  DOWNLOAD_TRAINING_ASSET: 'trainings/DOWNLOAD_TRAINING_ASSET',

  // public actions
  PUBLIC_GET_TRAINING_CATEGORIES: 'trainings/PUBLIC_GET_TRAINING_CATEGORIES',
  PUBLIC_GET_TRAININGS_LIST: 'trainings/PUBLIC_GET_TRAININGS_LIST',
  PUBLIC_GET_TRAINING: 'trainings/PUBLIC_GET_TRAINING',
  PUBLIC_GET_TRAINING_QUESTIONS: 'trainings/PUBLIC_GET_TRAINING_QUESTIONS',
  PUBLIC_SUBMIT_TRAINING_QUESTIONS: 'trainings/PUBLIC_SUBMIT_TRAINING_QUESTIONS',

}

export default actions
