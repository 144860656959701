import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'


const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    getInviteDetails: jwt.getInviteDetails,
    updateProfile: jwt.updateProfile,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {
    yield put({
      type: 'auth/LOAD_CURRENT_ACCOUNT',
    })

    const redirectPath = history?.location?.state?.redirectTo || "/"
    console.log("redirectPath",redirectPath);
    yield history.push(redirectPath,{
      redirectTo: ''
    })

    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  } else {
    notification.error({
      message: 'Authentication failed',
      description: 'Username or password is incorrect',
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, payload)
  if (success) {
    yield put({
      type: 'auth/LOGIN',
      payload: {
        email,
        password,
      },
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_INVITE_DETAILS({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].getInviteDetails, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        inviteDetails: response.data,
      },
    })
  }
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const {
      id,
      email,
      firstName,
      lastName,
      avatar,
      role,
      photo,
      phone,
      accountComplete,
      accountCompletePercent,
      newPatientsSinceLastLogin,
      volunteer,
      signature,
    } = response
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        id,
        firstName,
        lastName,
        email,
        avatar,
        photo,
        phone,
        role,
        accountComplete,
        accountCompletePercent,
        volunteer,
        signature,
        newPatientsSinceLastLogin,
        authorized: true,
      },
    })
  } else {
    yield put({ type: 'auth/RESET_STATE' })
  }
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      accountComplete: false,
      accountCompletePercent: 0,
      volunteer: false,
      authorized: false,
      loading: false,
    },
  })
}

export function* UPDATE_PROFILE({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].updateProfile, payload)
  if (success) {
    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })
    notification.success({
      message: 'Profile updated',
      description: 'You have successfully updated your profile!',
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_INVITE_DETAILS, LOAD_INVITE_DETAILS),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
