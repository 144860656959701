import 'moment/locale/it'

import { ConnectedRouter } from 'connected-react-router'
import Layout from 'layouts'
import moment from 'moment'
import React, { lazy, memo, Suspense } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

const routes = [
  // Public routes
  {
    path: '/refer-patient',
    Component: lazy(() => import('pages/referPatient')),
    exact: true,
  },
  {
    path: '/training-pacienti',
    Component: lazy(() => import('pages/public/training/pacienti/list')),
    exact: true,
  },
  {
    path: '/training-pacienti/:id',
    Component: lazy(() => import('pages/public/training/pacienti-details')),
    exact: true,
  },
  {
    path: '/training/:id',
    Component: lazy(() => import('pages/public/training/details')),
    exact: true,
  },
  {
    path: '/training/:id/quiz',
    Component: lazy(() => import('pages/public/training/quiz')),
    exact: true,
  },
  {
    path: '/',
    Component: lazy(() => import('pages/public/home')),
    // Component: lazy(() => import('pages/public/training/list')),
    exact: true,
  },

  // training private routes
  {
    path: '/training',
    Component: lazy(() => import('pages/public/training/list')),
    exact: true,
  },

  // Dashboard routes
  {
    path: '/manager/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  // Patients routes
  {
    path: '/manager/patients',
    Component: lazy(() => import('pages/patients/list')),
    exact: true,
  },
  {
    path: '/manager/patients/create',
    Component: lazy(() => import('pages/patients/create')),
    exact: true,
  },
  {
    path: '/manager/patients/update/:id',
    Component: lazy(() => import('pages/patients/update')),
    exact: true,
  },

  // Checkups routes
  {
    path: '/manager/checkups/create/:id/:type?',
    Component: lazy(() => import('pages/checkups/create')),
    exact: true,
  },
  {
    path: '/manager/checkups/update/:checkupId',
    Component: lazy(() => import('pages/checkups/update')),
    exact: true,
  },

  // Training routes
  {
    path: '/manager/trainings',
    Component: lazy(() => import('pages/training/list')),
    exact: true,
  },
  {
    path: '/manager/trainings/create',
    Component: lazy(() => import('pages/training/create')),
    exact: true,
  },
  {
    path: '/manager/trainings/update/:id',
    Component: lazy(() => import('pages/training/update')),
    exact: true,
  },

  // Auth routes
  {
    path: '/manager/adauga-vizita',
    Component: lazy(() => import('pages/#oldstuff/appointments/create')),
    exact: true,
  },

  // Profile page
  {
    path: '/manager/profile',
    Component: lazy(() => import('pages/auth/profile')),
    exact: true,
  },

  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register(/)?:inviteCode?',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = memo(({ history, routerAnimation }) => {
  const intl = useIntl()

  moment.locale(intl.locale)
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/manager"
                      render={() => <Redirect to="/manager/dashboard" />}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
})

export default connect(mapStateToProps)(Router)
