import actions from './actions'

const initialState = {
  list: { data: [], meta: {} },
  checkups: { data: [], meta: {} },

  patient: {},
  documents: [],
  checkup: {},

  loading: false,
  loadingCheckups: false,
}

export default function patientsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
