export async function getMenuData() {
  return [
    {
      category: true,
      title: 'Navigation',
    },
    {
      title: 'Dashboard',
      transKey: 'menu.dashboard',
      key: 'dashboard',
      url: '/manager/dashboard',
      icon: 'fe fe-home',
      roles: ['admin', 'manager', 'doctor'],
    },
    {
      title: 'Patients',
      transKey: 'menu.patients',
      key: 'patients',
      url: '/manager/patients',
      icon: 'fe fe-user',
      roles: ['admin', 'manager', 'doctor'],
    },
    {
      title: 'Calendar Programari',
      transKey: 'menu.appointments',
      key: 'appointments',
      url: '#',
      icon: 'fe fe-calendar',
      roles: ['admin', 'manager', 'doctor'],
    },

    {
      title: 'Administration',
      transKey: 'menu.administration',
      category: true,
      roles: ['admin'],
    },
    {
      title: 'Formare',
      transKey: 'menu.training',
      key: 'training',
      url: '/manager/trainings',
      icon: 'fe fe-briefcase',
      roles: ['admin'],
    },
    {
      title: 'Users',
      transKey: 'menu.users',
      key: 'users',
      url: '/manager/users',
      icon: 'fe fe-user',
      roles: ['admin'],
    },
  ]
}

export async function getMenuTopData() {
  return [
    {
      category: true,
      title: 'Navigation',
    },
    {
      title: 'Informații pentru pacienți',
      transKey: 'menu.public.home',
      key: 'training-pacienti',
      url: '/training-pacienti',
    },
    {
      title: 'Informații pentru medici',
      transKey: 'menu.public.training',
      key: 'training-medici',
      url: '/training',
    },
  ]
}
