import apiClient from 'services/axios'

const checkupsApi = {
  getCheckups(params) {
    return apiClient
      .get('checkups', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getCheckup(id) {
    return apiClient
      .get(`checkups/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  createCheckup(data) {
    return apiClient
      .post('checkups', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  updateCheckup(data) {
    return apiClient
      .put('checkups', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  deleteCheckup(id) {
    return apiClient
      .delete(`checkups/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
}

export default checkupsApi
