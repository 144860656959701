import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'

import patients from './patients/sagas'
import checkups from './checkups/sagas'
import trainings from './trainings/sagas'

export default function* rootSaga() {
  yield all([
    auth(),
    menu(),
    settings(),
    patients(),
    checkups(),
    trainings(),
  ])
}
