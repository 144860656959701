import localeAntd from 'antd/es/locale/en_US'

const messages = {
  /////////////////////=============== General ===============/////////////////////
  'btn.details': 'Details',
  'btn.remove': 'Remove',
  'btn.ok': 'OK',
  'btn.submit': 'Submit',
  'btn.preview': 'Preview',
  'btn.signUp': 'Sign up',
  'btn.signIn': 'Sign in',
  'btn.cancel': 'Cancel',
  'btn.download': 'Download',
  'btn.upload': 'Upload',

  'list.actions': 'Actions',

  'tabs.general': 'General',
  'tabs.docs': 'Documents',

  /////////////////////=============== Dashboard ===============/////////////////////
  'dashboard.page.title': 'Dashboard',
  'dashboard.page.heading': 'Statistics',
  'dashboard.widget.newPatients': 'New Patients',
  'dashboard.widget.patients': 'Total Patients',
  'dashboard.widget.appointments': 'Appointments',
  'dashboard.widget.courses': 'Courses',

  /////////////////////=============== Doctors ===============/////////////////////
  'doctor.firstname': 'First name',
  'doctor.lastname': 'Last name',
  'doctor.email': 'Email',
  'doctor.password': 'Last name',
  'doctor.speciality': 'Speciality',
  'doctor.workplace': 'Workplace',
  'doctor.doctorIndexNumber': 'C.U.I.M',

  /////////////////////=============== Patients ===============/////////////////////
  'patients.firstname': 'First name',
  'patients.lastname': 'Last name',
  'patients.cnp': 'CNP',
  'patients.dob': 'Date of birth',
  'patients.birthPlace': 'Place of birth',
  'patients.gender': 'Gender',
  'patients.gender.m': 'Male',
  'patients.gender.f': 'Female',
  'patients.phone': 'Phone',
  'patients.email': 'Email',
  'patients.address': 'Adress',
  'patients.referralReason': 'Referral reason',
  'patients.status': 'Status',
  // 'patients.status.A': 'Active',
  // 'patients.status.D': 'Deceased',
  // 'patients.status.PR': 'Referred patient',
  // 'patients.status.CV': 'In course of investigation',
  // 'patients.status.NE': 'Uneligible for evaluation',
  // 'patients.status.CTTRC': 'Confirmed cardiomiopathy TTR',
  // 'patients.status.SCE': 'Suspect de amiloidoza cardiaca de alta etioloie', // i gave up at this point..
  // 'patients.status.FAC': 'Fara amiloidoza cardiaca',
  'patients.covidVaccinated': 'SARS COV2 Vaccinated',
  'patients.consent': 'Informed consent',
  'patients.doctor.firstname': 'First name',
  'patients.doctor.lastname': 'Last name',
  'patients.doctor.speciality': 'Speciality',
  'patients.doctor.code': 'Doctor code',
  'patients.doctor.phone': 'Phone',
  'patients.doctor.email': 'Email',
  'patients.doctor.btn.add': 'Add doctor',
  'patients.doctor.btn.delete': 'Remove doctor',
  'patients.doctor.notFound': 'No doctors found.',

  'patients.list.page.title': 'Patients',
  'patients.list.page.heading': 'Patients list',
  'patients.list.btn.add-patient': 'Add patient',
  'patients.list.name': 'Name',

  'patients.create.page.title': 'Add new patient',
  'patients.create.page.heading': 'Add new patient',

  'patients.update.page.title': 'Update patient',
  'patients.update.page.heading': 'Update patient',
  'patients.update.btn.add-screening': 'Add screening',
  'patients.update.btn.add-enrollment': 'Add enrollment',
  'patients.update.btn.add-follow-up': 'Add follow up',
  'patients.update.tabs.doctor': 'Doctors',
  'patients.update.tabs.checkups': 'Checkups',

  'patients.status.placeholder': 'Update Patient Status',
  'patients.PR': 'Referred patient',
  'patients.CV': 'Pending investigation',
  'patients.NE': 'Not eligible for screening',
  'patients.CTTRC': 'TTR amyloid cardiomyopathy',
  'patients.SCE': 'Non TTR amyloid cardiomyopathy',
  'patients.FAC': 'No evidence of amyloid cardiomyopathy',
  'patients.LOS': 'Lost to screening',
  'patients.DBD': 'Deceased before diagnosis',

  'patients.reasonLos': 'Reason for lost of screening',
  'patients.viewReasonLos': 'View reason',

  'patients.documents.upload.title': 'Upload document',
  'patients.documents.btn.add': 'Upload document',
  'patients.documents.name': 'Name',
  'patients.documents.document': 'Document',
  'patients.documents.createdAt': 'Uploaded at',
  'patients.documents.type': 'Type',
  'patients.documents.type.medicalLetter': 'Medical letter',
  'patients.documents.type.ecg': 'ECG',
  'patients.documents.type.echocardiography': 'Echocardiography',
  'patients.documents.type.rmn': 'RMN',
  'patients.documents.type.scintigraphy': 'Scintigraphy',
  'patients.documents.type.covidCertificate': 'Covid certificate',
  'patients.documents.type.other': 'Other',
  'patients.documents.dropzone.text1': 'Upload document',
  'patients.documents.dropzone.text2': 'Drag and drop files here',

  'referPatient.page.title': 'Refer patient',
  'referPatient.page.heading': 'Refer patient',
  'referPatient.section.doctor': 'Doctor information',
  'referPatient.section.patient': 'Patient information',
  'referPatient.section.documents': 'Patient documents',
  'referPatient.terms': 'I agree with the terms and conditions.',
  'referPatient.btn.submit': 'Refer patient',

  'patients.checkups.page.title': 'Add checkup',
  'patients.checkups.page.heading': 'Add checkup',
  'patients.checkups.page.heading.screening': 'Add screening',
  'patients.checkups.page.heading.enrollment': 'Add enrollment',
  'patients.checkups.page.heading.follow-up': 'Add follow up',
  'patients.checkupsUpdate.page.title': 'Update checkup',
  'patients.checkupsUpdate.page.heading': 'Update checkup',
  'patients.checkupsUpdate.page.heading.screening': 'Update screening',
  'patients.checkupsUpdate.page.heading.enrollment': 'Update enrollment',
  'patients.checkupsUpdate.page.heading.follow-up': 'Update follow up',
  'patients.checkups.btn.add': 'Add checkup',
  'checkups.type': 'Type',
  'checkups.createdAt': 'Date',

  'checkups.date': 'Date',
  'checkups.clinical': 'Clinical',
  'checkups.clinical.symptoms': 'Symptoms',
  'checkups.clinical.symptoms.dyspnoea': 'Dyspnoea',
  'checkups.clinical.symptoms.nyha': 'NYHA',
  'checkups.clinical.symptoms.angina': 'Angina',
  'checkups.clinical.symptoms.syncope': 'Syncope',
  'checkups.clinical.symptoms.palpitations': 'Palpitations',
  'checkups.clinical.symptoms.palpitations.details': 'Palpitation details',
  'checkups.clinical.physicalExam': 'Physical Exam',
  'checkups.clinical.physicalExam.taClinoLeft': 'BP sitting left',
  'checkups.clinical.physicalExam.taClinoRight': 'BP sitting right',
  'checkups.clinical.physicalExam.taOrtho': 'BP standing',
  'checkups.clinical.physicalExam.hr': 'HR',
  'checkups.clinical.physicalExam.murmurs': 'Heart murmurs',
  'checkups.clinical.physicalExam.weight': 'Weight',
  'checkups.clinical.physicalExam.height': 'Height',

  'checkups.clinical.signsOfHeartFailure': 'Signs of heart failure',
  'checkups.clinical.signsOfHeartFailure.cardiacEdema': 'Cardiac edema',
  'checkups.clinical.signsOfHeartFailure.turgidJugular': 'Increased jugular venous pressure',
  'checkups.clinical.signsOfHeartFailure.hepatomegaly': 'Hepatomegaly',
  'checkups.clinical.signsOfHeartFailure.stasisRallies': 'Basal crackles',
  'checkups.clinical.signsOfHeartFailure.noise3': '3rd heart sound',

  'checkups.clinical.specificSignsOfAmyloidosis': 'Extracardiac red flags',
  'checkups.clinical.specificSignsOfAmyloidosis.bilateralCarpalTunnelSyndrome':
    'Bilateral carpal tunnel syndrome',
  'checkups.clinical.specificSignsOfAmyloidosis.bicepsTendonRupture':
    'Spontaneous biceps tendon rupture',
  'checkups.clinical.specificSignsOfAmyloidosis.lumbarSpinalStenosis': 'Lumbar spinal stenosis',
  'checkups.clinical.specificSignsOfAmyloidosis.kneeArthropathy': 'Hip / knee arthropathy',
  'checkups.clinical.specificSignsOfAmyloidosis.vitreousDeposits': 'Vitreous deposits',
  'checkups.clinical.specificSignsOfAmyloidosis.deafness': 'Deafness',
  'checkups.clinical.specificSignsOfAmyloidosis.polyneuropathy': 'Polyneuropathy',
  'checkups.clinical.specificSignsOfAmyloidosis.previouslyHypertensivePatients':
    'Previously hypertensive patients',
  'checkups.clinical.specificSignsOfAmyloidosis.orthostaticHypotension': 'Orthostatic hypotension',
  'checkups.clinical.specificSignsOfAmyloidosis.urinaryIncontinence': 'Urinary incontinence',
  'checkups.clinical.specificSignsOfAmyloidosis.urinaryRetention': 'Urinary retention',
  'checkups.clinical.specificSignsOfAmyloidosis.erectileDysfunction': 'Erectile dysfunction',
  'checkups.clinical.specificSignsOfAmyloidosis.constipationDiarheea': 'Constipation/Diarheea',
  'checkups.clinical.specificSignsOfAmyloidosis.unexplainedWeightLoss': 'Unexplained weight loss',
  'checkups.clinical.specificSignsOfAmyloidosis.lossOfApetite': 'Loss of apetite',
  'checkups.clinical.specificSignsOfAmyloidosis.skinBruising': 'Skin bruising',
  'checkups.clinical.specificSignsOfAmyloidosis.macroglossia': 'Macroglossia',
  // 'checkups.clinical.specificSignsOfAmyloidosis.carpalCanal': "Carpal canal syndrome",
  // 'checkups.clinical.specificSignsOfAmyloidosis.hipKneeArthropathy': "Hip / knee arthropathy",
  // 'checkups.clinical.specificSignsOfAmyloidosis.hipertensionIntolerance': "Hypotension intolerance",
  // 'checkups.clinical.specificSignsOfAmyloidosis.orthostaticHypotension': "Orthostatic hypotension",
  // 'checkups.clinical.specificSignsOfAmyloidosis.erectileDysfunction': "Erectile dysfunction",
  // 'checkups.clinical.specificSignsOfAmyloidosis.urinaryIncontinence': "Urinary incontinence",
  // 'checkups.clinical.specificSignsOfAmyloidosis.urinaryRetention': "Urinary retention",
  // 'checkups.clinical.specificSignsOfAmyloidosis.alterningConstipationDiarrhea': "Alternating constipation / diarrhea",
  // 'checkups.clinical.specificSignsOfAmyloidosis.unintentionalWeightLoss': "Unintentional weight loss",
  // 'checkups.clinical.specificSignsOfAmyloidosis.lossOfApetite': "Loss of apetite",
  // 'checkups.clinical.specificSignsOfAmyloidosis.severeFatigue': "Severe fatigue",
  // 'checkups.clinical.specificSignsOfAmyloidosis.macroglossia': "Macroglossia",
  // 'checkups.clinical.specificSignsOfAmyloidosis.easyBruising': "Easy bruising",
  // 'checkups.clinical.specificSignsOfAmyloidosis.skinFragility': "Skin fragility",
  // 'checkups.clinical.specificSignsOfAmyloidosis.nailDystrophy': "Nail dystrophy",
  // 'checkups.clinical.specificSignsOfAmyloidosis.waxyThickenedSkin': "Waxy or thickened skin",
  // 'checkups.clinical.specificSignsOfAmyloidosis.proteinuria': "Proteinuria",
  // 'checkups.clinical.specificSignsOfAmyloidosis.heartFailure': "Heart failure",
  // 'checkups.clinical.specificSignsOfAmyloidosis.arrhythmia': "Arrhythmia",
  // 'checkups.clinical.specificSignsOfAmyloidosis.erectileDisfunction': "Erectile disfunction",
  // 'checkups.clinical.specificSignsOfAmyloidosis.gustatorySweating': "Gustatory sweating",
  // 'checkups.clinical.specificSignsOfAmyloidosis.hepatosplenomegaly': "Hepatosplenomegaly",
  'checkups.clinical.specificSignsOfAmyloidosis.other': 'Other',

  'checkups.clinical.kansasCityQuestionnaire': 'Kansas City Cardiomyopathy Questionnaire',
  'checkups.clinical.6MinuteWalkTest': '6 minute walk test',
  'checkups.clinical.6MinuteWalkTest.6mwt': '6 MWT D',
  'checkups.clinical.6MinuteWalkTest.borgPre': 'Borg pre',
  'checkups.clinical.6MinuteWalkTest.borgPost': 'Borg post',
  'checkups.clinical.6MinuteWalkTest.cardiomyopathyHistory': 'Family history of cardiomyopathy',
  'checkups.clinical.6MinuteWalkTest.amyloidosisHistory': 'Family history of TTR amyloidosis',
  'checkups.clinical.6MinuteWalkTest.diseaseHistory': 'Disease history',
  'checkups.clinical.6MinuteWalkTest.notPerformed': 'Could not be performed',
  'checkups.clinical.6MinuteWalkTest.reason': 'Reason',

  'checkups.lab': 'Laboratory',
  'checkups.lab.KLambda': 'Kappa/Lambda ratio in serum',
  'checkups.lab.prealbumin': 'Prealbumin',
  'checkups.lab.troponin': 'Troponin',
  'checkups.lab.ntprobnp': 'NT-proBNP',
  'checkups.lab.urea': 'Urea',
  'checkups.lab.creatinine': 'Creatinine',
  'checkups.lab.rfg': 'eGFR',
  'checkups.lab.protenuria': 'Protenuria',
  'checkups.lab.BNP': 'BNP',
  'checkups.lab.urinaryKappa': 'Urinary kappa chains by immune-electrophoresis',
  'checkups.lab.urinaryLambda': 'Urinary lambda chains by immune-electrophoresis',
  'checkups.lab.serumKappa': 'Serum kappa chains by immune-electrophoresis',
  'checkups.lab.serumLambda': 'Serum lambda chains by immune-electrophoresis',
  'checkups.lab.quantitativeProteinuria': 'Quantitative proteinuria',

  'checkups.history': 'History',
  'checkups.history.amyloidosis': 'Family history of TTR amyloidosis',
  'checkups.history.cardiomyopathy': 'Family history of cardiomyopathy',
  'checkups.history.diseaseHistory': 'Disease history',

  'checkups.ecg12.ecgRedFlags': 'ECG red flags',
  'checkups.ecg12.ecgRedFlags.pseudoInfarct': 'Pseudo-infarction pattern',
  'checkups.ecg12.ecgRedFlags.qrsVoltage':
    'Low / Decreased QRS voltage to degree of wall thickness',
  'checkups.ecg12.ecgRedFlags.avConductionDisease': 'AV conduction disease',

  'checkups.ecg12': '12 lead ECG',
  'checkups.ecg12.atrialRithm': 'Rhythm',
  'checkups.ecg12.atrialRithm.SR': 'SR',
  'checkups.ecg12.atrialRithm.AF': 'AF',
  'checkups.ecg12.atrialRithm.AFl': 'AFl',
  'checkups.ecg12.atrialRithm.AT': 'AT',

  'checkups.ecg12.hyperthrophyCriteria': 'Conduction',
  'checkups.ecg12.hyperthrophyCriteria.hvs': 'HVS',
  'checkups.ecg12.hyperthrophyCriteria.isl': 'ISL',
  'checkups.ecg12.hyperthrophyCriteria.rInAvl': 'R in AVL',
  'checkups.ecg12.hyperthrophyCriteria.cornell': 'Cornell',
  'checkups.ecg12.hyperthrophyCriteria.microvoltage': 'Microvoltage',
  'checkups.ecg12.hyperthrophyCriteria.additionalData': 'Additional data',

  // new fields
  'checkups.ecg12.ecgMeasurements': 'ECG Measurements',
  'checkups.ecg12.ecgMeasurements.pWaveAmplitude': 'P wave amplitude',
  'checkups.ecg12.ecgMeasurements.pWaveWidth': 'P wave width',
  'checkups.ecg12.ecgMeasurements.PQInterval': 'PQ Interval',
  'checkups.ecg12.ecgMeasurements.QRSWidth': 'QRS width',
  'checkups.ecg12.ecgMeasurements.QTm': 'QTm',
  'checkups.ecg12.ecgMeasurements.RRInterval': 'RR Interval',
  'checkups.ecg12.ecgMeasurements.QTc': 'QTc',
  'checkups.ecg12.ecgMeasurements.sInV1': 'S in V1',
  'checkups.ecg12.ecgMeasurements.rInV5': 'R in V5',
  'checkups.ecg12.ecgMeasurements.LVH': 'LVH',
  'checkups.ecg12.ecgMeasurements.rInV1': 'R in V1',
  'checkups.ecg12.ecgMeasurements.sInV5': 'S in V5',
  'checkups.ecg12.ecgMeasurements.RVH': 'RVH',
  // 'checkups.ecg12.ecgMeasurements.rInVL': 'R in aVL',
  'checkups.ecg12.ecgMeasurements.cornellIndex': 'Cornell index',
  'checkups.ecg12.ecgMeasurements.RinaVL': 'R in aVL',
  'checkups.ecg12.ecgMeasurements.comment': 'Comment',
  'checkups.ecg12.ecgMeasurements.file': 'File',

  // 'checkups.ecg12.ecgMeasurements.pq': "PQ",
  // 'checkups.ecg12.ecgMeasurements.pWaveTime': "P wave time",
  // 'checkups.ecg12.ecgMeasurements.qrs': "QRS",
  // 'checkups.ecg12.ecgMeasurements.qtm': "QTm",
  // 'checkups.ecg12.ecgMeasurements.rr': "RR",
  // 'checkups.ecg12.ecgMeasurements.qtcBazett': "QTC - Bazett function",

  'checkups.ecg12.conductionDisorders': 'Conduction disorders',
  'checkups.ecg12.conductionDisorders.disorders': 'AV conduction disorders',
  'checkups.ecg12.conductionDisorders.disorders.bavgr1': 'First degree AVB',
  'checkups.ecg12.conductionDisorders.disorders.iim1': 'Second degree AVB, Mobitz 1',
  'checkups.ecg12.conductionDisorders.disorders.iim2': 'Second degree AVB, Mobitz 2',
  'checkups.ecg12.conductionDisorders.disorders.iii': 'Third degree AVB',
  'checkups.ecg12.conductionDisorders.brs': 'LBBB',
  'checkups.ecg12.conductionDisorders.bfsa': 'BFSA',
  'checkups.ecg12.conductionDisorders.brd': 'RBBB',
  'checkups.ecg12.conductionDisorders.nonSpecific':
    'Nonspecific intraventricular conduction disorders',

  'checkups.ecg12.features': 'Features',
  'checkups.ecg12.features.qrsFragmentation': 'QRS Fragmentation',
  'checkups.ecg12.features.preexcitation': 'Preexcitation',
  'checkups.ecg12.features.ecgconclusion': 'ECG Conclusion',
  'checkups.ecg12.features.pseudoInfarction': 'Pseudo-infarction',
  'checkups.ecg12.features.LVH': 'LVH',
  'checkups.ecg12.features.microvoltage': 'microvoltage',

  'checkups.ecgHolter': 'Holter ECG',
  'checkups.ecgHolter.fcMax': 'HR Max',
  'checkups.ecgHolter.fcMin': 'HR Min',
  'checkups.ecgHolter.3secTimeouts': 'pauses>3 sec',
  'checkups.ecgHolter.rhythmDisorders': 'Arrhythmias',
  'checkups.ecgHolter.rhythmDisorders.esa': 'ESA',
  'checkups.ecgHolter.rhythmDisorders.fia': 'FiA',
  'checkups.ecgHolter.rhythmDisorders.FIA': 'FIA',
  'checkups.ecgHolter.rhythmDisorders.tachytrial': 'Tachytrial',
  'checkups.ecgHolter.rhythmDisorders.esv': 'ESV',
  'checkups.ecgHolter.rhythmDisorders.tvns': 'TVNS',
  'checkups.ecgHolter.rhythmDisorders.tvs': 'TVS',
  'checkups.ecgHolter.avCondutionDisorders': 'AV conduction disorders (all AVB)',
  'checkups.ecgHolter.fcFluctuation': 'HR variability',
  'checkups.ecgHolter.ecgConclusion': 'Holter ECG conclusion',

  'checkups.echocardiography': 'Echocardiography',
  'checkups.echocardiography.general': 'General',
  'checkups.echocardiography.ivs': 'IVS',
  'checkups.echocardiography.lvpw': 'LVPW',
  'checkups.echocardiography.lvDiameter': 'LV diameters',
  'checkups.echocardiography.lvMass': 'LV mass',
  'checkups.echocardiography.lvIndexedVol': 'LV vol indexed',
  'checkups.echocardiography.lvef': 'LVEF',
  'checkups.echocardiography.method': 'Method',
  'checkups.echocardiography.segKineticDescMyoApp':
    'Segmental kinetic description',

  'checkups.echocardiography.aortic': 'Aortic Valve',
  'checkups.echocardiography.aortic.vMaxAo': 'V max Ao',
  'checkups.echocardiography.aortic.maxAoGradient': 'Max Ao gradient',
  'checkups.echocardiography.aortic.mediumAoGradient': 'Medium Ao gradient',
  'checkups.echocardiography.aortic.vtiAo': 'VTI Ao',
  'checkups.echocardiography.aortic.vtilvot': 'VTI LVOT',
  'checkups.echocardiography.aortic.aorticValveArea': 'Aortic valve area',

  'checkups.echocardiography.brightVentricularMyocardium': 'Bright ventricular myocardium',
  'checkups.echocardiography.brightVentMyo.laDiameter': 'LA diameter',
  'checkups.echocardiography.brightVentMyo.laArea': 'LA area',
  'checkups.echocardiography.brightVentMyo.indexedAsVol': 'Indexed LA volume',
  'checkups.echocardiography.brightVentMyo.lasr': 'LAS R',
  'checkups.echocardiography.brightVentMyo.ac4cBasal': 'RV A4C basal',
  'checkups.echocardiography.brightVentMyo.lasct': 'LAS CT',
  'checkups.echocardiography.brightVentMyo.lascd': 'LAS CD',
  'checkups.echocardiography.brightVentMyo.adArea': 'AD area',
  'checkups.echocardiography.brightVentMyo.vda4cSt': 'VD A4c ST',
  'checkups.echocardiography.brightVentMyo.vda4cMedio': 'RV A4c medioventricular',
  'checkups.echocardiography.brightVentMyo.vda4cLong': 'RV A4c Longitudinal',
  'checkups.echocardiography.brightVentMyo.rvot': 'RVOT',
  'checkups.echocardiography.brightVentMyo.vdWallThikness': 'RV free wall thikness',
  'checkups.echocardiography.brightVentMyo.tapse': 'TAPSE',
  'checkups.echocardiography.brightVentMyo.aoAnnulus': 'Ao annulus',
  'checkups.echocardiography.brightVentMyo.aoAsc': 'Ao asc',
  'checkups.echocardiography.brightVentMyo.aoArch': 'Ao arch',
  'checkups.echocardiography.brightVentMyo.aoToracicOpening': 'Ao thoracic',
  'checkups.echocardiography.brightVentMyo.ivc': 'IVC',
  'checkups.echocardiography.brightVentMyo.ivcCollapse': 'IVC Collapse',
  'checkups.echocardiography.brightVentMyo.pericardium': 'Pericardium',

  'checkups.echocardiography.brightVentMyo.aoabd': 'Ao abd',
  'checkups.echocardiography.brightVentMyo.valveDesc': 'Aortic valve description',
  'checkups.echocardiography.brightVentMyo.opening': 'Opening',
  'checkups.echocardiography.brightVentMyo.closing': 'Closing',
  'checkups.echocardiography.brightVentMyo.regurgitation': 'Regurgitation',
  'checkups.echocardiography.brightVentMyo.regurgitation.easy': 'Easy',
  'checkups.echocardiography.brightVentMyo.regurgitation.medium': 'Medium',
  'checkups.echocardiography.brightVentMyo.regurgitation.severe': 'Severe',
  'checkups.echocardiography.brightVentMyo.fullReport': 'Upload full report',
  'checkups.echocardiography.brightVentMyo.relevantImages': 'Relevant images',

  'checkups.echocardiography.mitralValveDesc': 'Mitral Valve',
  'checkups.echocardiography.mitralValveDesc.e': 'E',
  'checkups.echocardiography.mitralValveDesc.a': 'A',
  'checkups.echocardiography.mitralValveDesc.ea': 'E/A',
  'checkups.echocardiography.mitralValveDesc.tde': 'TDE',
  'checkups.echocardiography.mitralValveDesc.regurgitation': 'Regurgitation',
  'checkups.echocardiography.mitralValveDesc.regurgitation.mild': 'Mild',
  'checkups.echocardiography.mitralValveDesc.regurgitation.moderate': 'Moderate',
  'checkups.echocardiography.mitralValveDesc.regurgitation.severe': 'Severe',
  'checkups.echocardiography.mitralValveDesc.ssiv': "S'SIV",
  'checkups.echocardiography.mitralValveDesc.esiv': "E'SIV",
  'checkups.echocardiography.mitralValveDesc.asiv': "A'SIV",
  'checkups.echocardiography.mitralValveDesc.easiv': "E'/A'SIV",
  'checkups.echocardiography.mitralValveDesc.eesiv': "E/E'SIV",
  'checkups.echocardiography.mitralValveDesc.spl': "S'PL",
  'checkups.echocardiography.mitralValveDesc.epl': "E'PL",
  'checkups.echocardiography.mitralValveDesc.apl': "A'PL",
  'checkups.echocardiography.mitralValveDesc.ee': "E/E",
  'checkups.echocardiography.mitralValveDesc.eapl': "E'/A'PL",
  'checkups.echocardiography.mitralValveDesc.eepl': "E/E'PL",
  'checkups.echocardiography.mitralValveDesc.vMax': 'V max',


  'checkups.echocardiography.mitralValveDesc.ivc': 'IVC',
  'checkups.echocardiography.mitralValveDesc.icvCollapse': 'IVC collapse',
  'checkups.echocardiography.mitralValveDesc.pericardium': 'Pericardium',
  'checkups.echocardiography.mitralValveDesc.conclusion': 'Conclusion',

  'checkups.echocardiography.tricuspidValve': 'Tricuspid valve',
  'checkups.echocardiography.tricuspidValve.vMax': 'V max',
  'checkups.echocardiography.tricuspidValve.rvraGradient': 'RV-RA gradient',
  'checkups.echocardiography.tricuspidValve.paps': 'PAPs',
  'checkups.echocardiography.tricuspidValve.valveDescription': 'Valve description',

  'checkups.echocardiography.pulmonaryValve': 'Pulmonary valve',

  'checkups.echocardiography.echocardiographyRedFlags': 'Echocardiography red flags',
  'checkups.echocardiography.echocardiographyRedFlags.granualSparkling':
    'Granular sparkling of myocardium',
  'checkups.echocardiography.echocardiographyRedFlags.increasedRV': 'Increased RV wall thickness',
  'checkups.echocardiography.echocardiographyRedFlags.increasedAV': 'Increased valve thickness',
  'checkups.echocardiography.echocardiographyRedFlags.pericardialEffusion': 'Pericardial effusion',
  'checkups.echocardiography.echocardiographyRedFlags.reducedGLS':
    'Reduced GLS with apical sparing pattern',
  'checkups.echocardiography.echocardiographyRedFlags.thicksia': 'Thick IAS',
  'checkups.echocardiography.echocardiographyRedFlags.fullReport': 'Echocardiography full report',
  'checkups.echocardiography.echocardiographyRedFlags.relevantImages': 'Echocardiography relevant images',

  'checkups.scintigraphy': 'Scintigraphy',
  'checkups.scintigraphy.captureDegree': 'Perugini grade',
  'checkups.scintigraphy.contrHeartChestUptakeRatio': 'Controlateral heart / chest uptake ratio',
  'checkups.scintigraphy.fullReport': 'Upload full report',
  'checkups.scintigraphy.relevantImages': 'Relevant Images',
  'checkups.scintigraphy.efectuat': 'Not performed',
  'checkups.scintigraphy.displayFullReport': 'Scintigraphy full report',
  'checkups.scintigraphy.displayRelevandImages': 'Scintigraphy relevant images',

  'checkups.myocardialBiopsy': 'Myocardial biopsy',
  'checkups.myocardialBiopsy.file': 'Upload file',
  'checkups.myocardialBiopsy.congoRed': 'Congo Red; Immunohistochemistry; Mass spectrometry',

  'checkups.rmn': 'MRI',
  'checkups.rmn.file': 'File',
  'checkups.rmn.ecv': 'ECV',
  'checkups.rmn.lgeDistrib': 'LGE distribution',
  'checkups.rmn.lgeMass': 'LGE mass',
  'checkups.rmn.conclusion': 'Conclusion',
  'checkups.rmn.efectuat': 'Not performed',

  'checkups.geneticTesting': 'Genetic testing',
  'checkups.geneticTesting.file': 'File',
  'checkups.geneticTesting.ttrWild': 'TTR wild-type',
  'checkups.geneticTesting.ttrVariant': 'TTR variant',
  'checkups.geneticTesting.mutation': 'Mutation',
  'checkups.geneticTesting.mutationType': 'Mutation type',
  'checkups.geneticTesting.fullReport': 'Full Report',
  'checkups.geneticTesting.efectuat': 'Not performed',

  'checkups.familyTree': 'Family tree',

  'checkups.institutedTreatment': 'Prescribed treatment',
  'checkups.institutedTreatment.heartFailureTreatment': 'Heart failure treatment',
  'checkups.institutedTreatment.specificTreatment': 'Specific treatment',
  'checkups.institutedTreatment.otherDrugs': 'Other drugs',
  'checkups.institutedTreatment.interventional': 'Interventional',
  'checkups.institutedTreatment.furosemide': 'Furosemide',
  'checkups.institutedTreatment.spironolactone': 'Spironolactone',
  'checkups.institutedTreatment.betaBlocker': 'Beta-blocker',
  'checkups.institutedTreatment.ACEi': 'ACEi',
  'checkups.institutedTreatment.ARNI': 'ARNI',
  'checkups.institutedTreatment.ARB': 'ARB',
  'checkups.institutedTreatment.SGLT2i': 'SGLT2i',
  'checkups.institutedTreatment.tafamidis': 'Tafamidis',
  'checkups.institutedTreatment.CCBDHP': 'CCB DHP',
  'checkups.institutedTreatment.CCBNDHP': 'CCB NDHP',
  'checkups.institutedTreatment.VKA': 'VKA',
  'checkups.institutedTreatment.NOAC': 'NOAC',
  'checkups.institutedTreatment.amiodarone': 'Amiodarone',
  'checkups.institutedTreatment.digoxin': 'Digoxin',
  'checkups.institutedTreatment.ivabradine': 'Ivabradine',
  'checkups.institutedTreatment.aspirin': 'Aspirin',
  'checkups.institutedTreatment.P2Y12i': 'P2Y12i',
  'checkups.institutedTreatment.statin': 'Statin',
  'checkups.institutedTreatment.PCI': 'PCI',
  'checkups.institutedTreatment.CABG': 'CABG',
  'checkups.institutedTreatment.TAVI': 'TAVI',
  'checkups.institutedTreatment.PMK': 'PMK',
  'checkups.institutedTreatment.ICD': 'ICD',
  'checkups.institutedTreatment.CRT': 'CRT',

  'checkups.redFlagsSummary': 'Red Flags Summary',

  /////////////////////=============== Training ===============/////////////////////
  'training.name': 'Training',
  'training.category': 'Category',
  'training.caption': 'Caption',
  'training.description': 'Description',
  'training.image': 'Upload image',
  'training.image.explained': 'Drag and drop images here',
  'training.assets': 'Upload assets',
  'training.assets.explained': 'Drag and drop assets here',
  'training.questions': 'Questions',
  'training.question': 'Question',
  'training.answer': 'Answer',
  'training.answer.isCorrect': 'This answer is correct',
  'training.add-question': 'Add question',
  'training.remove-question': 'Remove question',
  'training.add-answer': 'Add answer',
  'training.remove-answer': 'Remove answer',

  'training.webinar.host-name': 'Host name',
  'training.webinar.host-email': 'Host email',
  'training.webinar.host-password': 'Host password',
  'training.webinar.date': 'Webinar date',


  'trainings.list.page.title': 'Training',
  'trainings.list.page.heading': 'Training list',
  'trainings.list.btn.add-training': 'Add training',
  'trainings.list.name': 'Name',
  'trainings.list.category': 'Category',

  'trainings.create.page.title': 'Add new training',
  'trainings.create.page.heading': 'Add new training',
  'trainings.create.tab.questions': 'Questions',
  'trainings.create.tab.webinars': 'Webinar config',
  'trainings.create.tab.sections': 'Course sections',

  'trainings.update.page.title': 'Update training',
  'trainings.update.page.heading': 'Update training',
  'trainings.update.tab.questions': 'Questions',
  'trainings.update.tab.webinars': 'Webinar config',
  'trainings.update.tab.sections': 'Course sections',

  /////////////////////=============== Register ===============/////////////////////
  'public.training.page.title': 'Training',

  /////////////////////=============== Register ===============/////////////////////
  'register.page.title': 'Create a new account',
  'register.page.alreadyHaveAccount': 'Already have an account?',

  'register.firstname': 'First name',
  'register.lastname': 'Last name',
  'register.email': 'Email',
  'register.password': 'Password',
  'register.password2': 'Confirm password',

  /////////////////////=============== Layout ===============/////////////////////
  'pagination.page': 'Page',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit profile',
  'topBar.profileMenu.logout': 'Logout',

  'topBar.nav.home': 'Home',
  'topBar.nav.training': 'Training',

  'topBar.quickLinks.signin': 'Sign in',
  'topBar.quickLinks.register': 'Register',

  /////////////////////=============== Form general validation ===============/////////////////////
  'documents.name': 'Name',
  'documents.type': 'Type',
  'documents.dropzone.text1': 'Upload documents',
  'documents.dropzone.text2': 'Drag and drop documents here',

  /////////////////////=============== Form general validation ===============/////////////////////
  'actions.confirm-delete': 'Are you sure you want to delete this?',
  'form.validation.select': 'Please select {field}',
  'form.validation.input': 'Please input {field}',
  'form.validation.valid': 'Please input a valid {field}',
  'form.validation.length': '{field} must be {length} characters long',

  /////////////////////=============== Errors ===============/////////////////////
  'error.user_not_found': 'Username or password is incorrect',

  /////////////////////=============== pagename ===============/////////////////////
  'pagename.page.title': 'Dashboard',
  'pagename.page.heading': 'Statistics',

  /////////////////////=============== menu ===============/////////////////////
  'menu.dashboard': 'Dashboard',
  'menu.patients': 'Patients',
  'menu.appointments': 'Appointments',
  'menu.administration': 'Administration',
  'menu.training': 'Training',
  'menu.users': 'Users',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
