import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import patientsApi from 'services/patientsApi'

import actions from './actions'

export function* GET_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.getPatient, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        patient: response.data,
      },
    })
  } else {
    yield history.push('/manager/patients')
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PATIENTS({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.getPatients, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.createPatient, payload)
  if (response && response.status === 200) {
    yield history.push(`/manager/patients/update/${response.data.id}`)
    notification.success({
      message: 'Success!',
      description: 'Patient created successfully!',
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.updatePatient, payload)
  if (response && response.status === 200) {
    yield history.push('/manager/patients')
    notification.success({
      message: 'Success!',
      description: 'Patient updated successfully!',
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.deletePatient, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Patient removed successfully!',
    })

    yield put({
      type: 'patients/GET_PATIENTS',
      payload,
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// export function* UPLOAD_PATIENT_DOCUMENT({ payload }) {
//   yield put({
//     type: 'patients/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const response = yield call(patientsApi.uploadDocument, payload.get('patientId'), payload)
//   if (response && response.status === 200) {
//     notification.success({
//       message: 'Success!',
//       description: 'Document uploaded successfully!',
//     })

//     if(payload.get('patientId')) {
//       yield put({
//         type: 'patients/GET_PATIENT',
//         payload: payload.get('patientId') ,
//       })
//       yield put({
//         type: 'patients/GET_PATIENT_DOCUMENTS',
//         payload: payload.get('patientId') ,
//       })
//     }

//   }
//   yield put({
//     type: 'patients/SET_STATE',
//     payload: {
//       loading: false,
//     },
//   })
// }

export function* UPLOAD_PATIENT_DOCUMENT({ payload }) {
  yield put({
    type: "patients/SET_STATE",
    payload: {
      loading: true
    }
  });
  const response = yield call(patientsApi.uploadDocument, payload.get('patientId'), payload);
  if ( response && response.status === 200 || response.status === 201 ) {
    notification.success({
      message: "Success",
      description: "Document uploaded successfully!"
    });
    if(payload.get('patientId')) {
      yield put({
        type: 'patients/GET_PATIENT',
        payload: payload.get('patientId') ,
      })
      yield put({
        type: 'patients/GET_PATIENT_DOCUMENTS',
        payload: payload.get('patientId') ,
      })
    }
  } else {
    notification.error({
      message: "Could not create data",
      description: response?.response?.data?.message || "System could not communicate properly with the endpoints"
    });
  }
    yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_PATIENT_CHECKUPS({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loadingCheckups: true,
    },
  })
  const response = yield call(patientsApi.getPatientCheckups, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        checkups: response.data,
      },
    })
  } else {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        checkups: [],
      },
    })
    notification.error({
      message: 'Error!',
      description: 'Unfortunately we could not retrieve the list of checkups for this patient. Please try again later'
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loadingCheckups: false,
    },
  })
}

export function* GET_PATIENT_DOCUMENTS({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.getPatientDocuments, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        documents: response.data,
      },
    })
  } else {
    yield put({
      type: 'patients/SET_STATE',
      payload: {
        documents: [],
      },
    })
    notification.error({
      message: 'Error!',
      description: 'Unfortunately we could not retrieve the list of documents for this patient. Please try again later'
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD_PATIENT_DOCUMENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(patientsApi.downloadPatientDocuments, payload.documentId, payload.patientId)
  if (response && response.status === 200) {
    if(response.data.url) {
      window.open(response.data.url)
    }
  } else {
    notification.error({
      message: 'Error!',
      description: 'Unfortunately we could not retrieve the document for this patient. Please try again later'
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* REFER_PATIENT({ payload }) {
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loadingReferral: true,
    },
  })
  const response = yield call(patientsApi.referPatient, payload)
  if (response && response.status === 200) {
    yield history.push('/')
    notification.success({
      message: 'Success!',
      description: 'Patient referred successfully!',
    })
  }
  yield put({
    type: 'patients/SET_STATE',
    payload: {
      loadingReferral: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PATIENTS, GET_PATIENTS),
    takeEvery(actions.CREATE_PATIENT, CREATE_PATIENT),
    takeEvery(actions.GET_PATIENT, GET_PATIENT),
    takeEvery(actions.UPDATE_PATIENT, UPDATE_PATIENT),
    takeEvery(actions.DELETE_PATIENT, DELETE_PATIENT),
    takeEvery(actions.UPLOAD_PATIENT_DOCUMENT, UPLOAD_PATIENT_DOCUMENT),
    takeEvery(actions.GET_PATIENT_CHECKUPS, GET_PATIENT_CHECKUPS),
    takeEvery(actions.GET_PATIENT_DOCUMENTS, GET_PATIENT_DOCUMENTS),
    takeEvery(actions.DOWNLOAD_PATIENT_DOCUMENT, DOWNLOAD_PATIENT_DOCUMENT),
    takeEvery(actions.REFER_PATIENT, REFER_PATIENT),
  ])
}
