const actions = {
  SET_STATE: 'checkups/SET_STATE',

  GET_CHECKUP: 'checkups/GET_CHECKUP',
  GET_CHECKUPS: 'checkups/GET_CHECKUPS',
  CREATE_CHECKUP: 'checkups/CREATE_CHECKUP',
  UPDATE_CHECKUP: 'checkups/UPDATE_CHECKUP',
  DELETE_CHECKUP: 'checkups/DELETE_CHECKUP',
}

export default actions
