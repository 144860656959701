import React from 'react';

import style from './style.module.scss';

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        {/*<a*/}
        {/*  href="https://sellpixels.com"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*  className={style.logo}*/}
        {/*>*/}
        {/*  SELLPIXELS*/}
        {/*  <span />*/}
        {/*</a>*/}
        {/*<br />*/}
        <p className="mb-0 text-right">© 2021 - ReactNR</p>
      </div>
    </div>
  )
}

export default Footer
