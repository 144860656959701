import apiClient from 'services/axios'

const trainingsApi = {
  getTrainings(params) {
    return apiClient
      .get('trainings', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getTraining(id) {
    return apiClient
      .get(`trainings/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  createTraining(data) {
    return apiClient
      .post('trainings', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  updateTraining(data) {
    return apiClient
      .put('trainings', data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  deleteTraining(id) {
    return apiClient
      .delete(`trainings/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  downloadAsset(id, trainingId) {
    return apiClient
      .get(`trainings/${trainingId}/assets/${id}/download`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getCategories() {
    return apiClient
      .get('trainings/categories')
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  // public routes
  getPublicCategories() {
    return apiClient
      .get('public/training/categories')
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPublicTrainings(params) {
    return apiClient
      .get('public/training', { params })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPublicTraining(id) {
    return apiClient
      .get(`public/training/${id}`)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  getPublicTrainingQuestion(id, index) {
    return apiClient
      .get(`public/training/${id}/questions`, { params: { index } })
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
  submitPublicTrainingQuestion(data) {
    return apiClient
      .post(`public/training/${data.trainingId}/questions/submit`, data)
      .then(response => response)
      .catch(err => {
        console.log(err)
        return err
      })
  },
}

export default trainingsApi
