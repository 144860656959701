const actions = {
  SET_STATE: 'auth/SET_STATE',
  RESET_STATE: 'auth/RESET_STATE',
  LOGIN: 'auth/LOGIN',
  REGISTER: 'auth/REGISTER',
  LOAD_INVITE_DETAILS: 'auth/LOAD_INVITE_DETAILS',
  LOAD_CURRENT_ACCOUNT: 'auth/LOAD_CURRENT_ACCOUNT',
  UPDATE_PROFILE: 'auth/UPDATE_PROFILE',
  LOGOUT: 'auth/LOGOUT',
}

export default actions
