import { Layout } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";

import { Link, withRouter } from 'react-router-dom';
import UserMenu from 'components/cleanui/layout/TopBar/UserMenu'
import MenuTop from 'components/cleanui/layout/Menu/MenuTop'

import style from './style.module.scss';

const mapStateToProps = ({ settings, auth }) => ({
  auth,
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  auth,
  children,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            [style.white]: authPagesColor === 'white',
          })}
        >
          <div
            className={classNames(`${style.topbar}`, {
              // [style.topbarGray]: isGrayTopbar,
            })}
          >
            <div className={style.logoContainer}>
              <div className={style.logo}>
                <Link to="/" className="mr-2">
                  <img src="/resources/images/logo.png" alt="ReactNR" />
                </Link>
              </div>
            </div>
            <div className={style.topNavContainer}>
              <MenuTop />
            </div>
            <div className={style.quickLinksContainer}>
              {auth.authorized && (
                <UserMenu />
              ) || (
                <ul className="quicklinks">
                  <li>
                    <Link to="/auth/login">
                      <FormattedMessage id="topBar.quickLinks.signin" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth/login">
                      <FormattedMessage id="topBar.quickLinks.register" />
                    </Link>
                  </li>
                </ul>
              )}

            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            <div className="text-center">&copy; 2021 - ReactNR</div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
