import React, { memo } from 'react'
import { Progress } from 'antd'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import styles from './styles.module.scss'

export default memo(() => {
  const { pathname } = useLocation()

  const role = useSelector(
    state => (state.auth && state.auth.role && state.auth.role.type) || false,
  )
  const accountComplete = useSelector(state => state.auth.accountComplete)
  const accountCompletePercent = useSelector(state => state.auth.accountCompletePercent)

  const ignorePaths = ['profile']
  if (
    ignorePaths.filter(p => pathname.toLowerCase().includes(p)).length > 0 ||
    !role ||
    (role && role !== 'volunteer') ||
    (role && role === 'volunteer' && accountComplete)
  ) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className="card">
        <div className="card-header card-header-flex">
          <div className="d-flex flex-column justify-content-center mr-auto">
            <div className="d-flex flex-row justify-content-center">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <h5 className="mb-0 mr-2">You're profile is not complete</h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>
            You're profile is almost complete, please complete <a href="/profile">your profile</a>{' '}
            so you can apply to studies.
          </p>
          <div className="info">
            <div className="progress-wrap">
              <div>Profile completion:</div>
              <Progress percent={accountCompletePercent} size="small" status="active" />
            </div>
            <a href="/profile" className="btn btn-primary btn-block">
              Profile
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})
