import { notification } from 'antd'
import axios from 'axios'
import store from 'store'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  // timeout: 1000,
  // headers: { 'Content-Type': 'multipart/form-data' }
})

apiClient.interceptors.request.use(request => {
  // console.log(request);
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling

  const {
    response,
    config: { url },
    ...rest
  } = error

  if (response) {
    const { data } = response
    const hideErrorsUrls = ['/auth/me', '/auth/login']
    if (response && data && hideErrorsUrls.indexOf(url) === -1) {
      notification.error({
        message: data && data.message ? data.message : '',
      })
    }
  }
})

export default apiClient
