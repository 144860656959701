import { notification } from 'antd'
import { history } from 'index'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import checkupsApi from 'services/checkupsApi'

import actions from './actions'

export function* GET_CHECKUP({ payload }) {
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(checkupsApi.getCheckup, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'checkups/SET_STATE',
      payload: {
        checkup: response.data,
      },
    })
  } else {
    yield history.push('/manager/checkups')
  }
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_CHECKUPS({ payload }) {
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(checkupsApi.getCheckups, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'checkups/SET_STATE',
      payload: {
        list: response.data,
      },
    })
  }
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CHECKUP({ payload }) {
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(checkupsApi.createCheckup, payload)
  if (response && response.status === 200) {
    yield history.push(`/manager/checkups/update/${response.data.id}`)
    notification.success({
      message: 'Success!',
      description: 'Checkup created successfully!',
    })
  }
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CHECKUP({ payload }) {
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(checkupsApi.updateCheckup, payload)
  if (response && response.status === 200) {
    yield put({
      type: 'checkups/GET_CHECKUP',
      payload: payload.get("id")
    })
    notification.success({
      message: 'Success!',
      description: 'Checkup updated successfully!',
    })
  }
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CHECKUP({ payload }) {
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(checkupsApi.deleteCheckup, payload.id)
  if (response && response.status === 200) {
    notification.success({
      message: 'Success!',
      description: 'Checkup removed successfully!',
    })

    yield put({
      type: 'checkups/GET_CHECKUPS',
      payload,
    })
  }
  yield put({
    type: 'checkups/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CHECKUPS, GET_CHECKUPS),
    takeEvery(actions.CREATE_CHECKUP, CREATE_CHECKUP),
    takeEvery(actions.GET_CHECKUP, GET_CHECKUP),
    takeEvery(actions.UPDATE_CHECKUP, UPDATE_CHECKUP),
    takeEvery(actions.DELETE_CHECKUP, DELETE_CHECKUP),
  ])
}
